import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Spacer from "../components/Spacer";
import ImageBlock from "../components/ImageBlock";
import Pc from "../images/pc.jpeg";
import Bethlem from "../images/bethlem.jpeg";
import Fbc from "../images/fbc.jpeg";
import Namb from "../images/namb.jpeg";
import Tct from "../images/tct.jpeg";

// markup
const PartnersPage = () => {
  return (
    <>
      <Header></Header>
      <div className="Index-page-content partners-page">
        <h1 style={{ textAlign: "center" }}>Partners/Afiliados</h1>
        <div className="row sqs-row">
          <ImageBlock
            title="NAMB- North American Mission Board"
            image={Namb}
            link="https://www.namb.net/"
          />
          <ImageBlock
            title="Florida Baptist Convention"
            image={Fbc}
            link="flBaptist.org"
          />
          <ImageBlock
            title="TCT- Network  (Treasuring Christ Together)"
            image={Tct}
            link="https://tctnetwork.org/"
          />
          <ImageBlock
            title="Bethlehem Baptist Church"
            image={Bethlem}
            link="https://bethlehem.church"
          />
          <ImageBlock
            title="Providence Road Church"
            image={Pc}
            link="http://prclife.org"
          />
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default PartnersPage;
